export const loadStockData = async () => {
    const headerStockMegamenuEl = document.querySelector(
        '[data-stock-megamenu]'
    );
    const headerStockWrapperEl = document.querySelector(
        '[data-header-stock-wrapper]'
    );

    const formData = new FormData();
    formData.append('action', 'get_header_stock_html');
    formData.append('lang', ajax.lang);

    const req = await fetch(ajax.url, {
        method: 'POST',
        body: formData,
    });

    if (!req) return;
    const { stock_megamenu_html, header_stock_html } = await req.json();

    if (headerStockMegamenuEl)
        headerStockMegamenuEl.innerHTML = stock_megamenu_html;
    if (headerStockWrapperEl)
        headerStockWrapperEl.innerHTML = header_stock_html;
};
